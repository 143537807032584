export default {
  word1001: 'Chinese',
  word1002: '邮箱',
  word1003: '密码',
  word1004: '忘记密码？',
  word1005: '登录',
  word1006: '注册',
  word1007: '用户协议',
  word1008: '个人隐私',
  word1009: '关于我们',
  word1010: '账号不能为空',
  word1011: '密码不能为空',
  word1012: '该账号已被冻结',
  word1013: '账号或密码错误',
  word1014: '登录成功',
  word1015: '昵称',
  word1016: '输入右方的数字',
  word1017: '获取验证码',
  word1018: '重复',
  word1019: '数字不能为空',
  word1020: '验证码不正确',
  word1021: '两次密码不一致',
  word1022: '昵称不能为空',
  word1023: '邮箱不能为空',
  word1024: '重复密码不能为空',
  word1025: '秒后重试',
  word1026: '注册成功',
  word1027: '邮箱验证码',
  word1028: '验证码不能为空',
  word1029: '新密码',
  word1030: '修改密码',
  word1031: '新密码不能为空',
  word1032: '修改成功',

  // 新增
  AccountPlaceholder: '邮箱',
  AccountEmptyTip: '邮箱账号不能为空',
  PasswordPlaceholder: '密码',
  PasswordEmptyTip: '密码不能为空',
  AccountForbidden: '该账号已被禁用',
  AccountPasswordError: '账号或密码错误',
  RealName: '真实姓名',
  RealNameEmptyTip: '真实姓名不能为空',
  RightNumber: '请输入右侧图片中的数字',
  NumberEmptyTip: '图片中数字不能为空',
  EmailPlaceholder: '邮箱',
  EmailEmptyTip: '邮箱账号不能为空',
  EmailError: '邮箱格式有误',
  GetEmailCode: '获取邮箱验证码',
  Retry: ' 秒后重试',
  Resend: '重新发送',
  EmailCodePlaceholder: '邮箱验证码',
  EmailCodeEmptyTip: '邮箱验证码不能为空',
  NewPassword: '新密码',
  InputNewPassword: '请输入新密码',
  AgainInputNewPassword: '请再次输入新密码',
  PasswordLength: '密码长度在 8 到 16 个字符',
  EmailHasRegister: '抱歉，该邮箱已经被注册，请使用其他邮箱',
  EmailHasExist: '用户邮箱已存在或已被删除',
  EmailCodeInvalid: '邮箱验证码无效，请重新获取',
  EmailCodeError: '邮箱验证码错误',
  Confirm: '确定',
  AccountInexistence: '该账号不存在，无法修改密码。',
  ChangePasswordTip: '您的账号为后台给出的默认密码，请尽快修改密码。',
  Tip: '提示',
  ChangePassword: '修改密码',
  DeleteTip: '您的账号已被删除，请联系您商会管理员。',
  AccountAbsentTip: '该账号不存在'
}
